import {mapActions} from 'vuex'

export default {
  methods: {
    ...mapActions(['set_refresh']),
    saveLoginInfo(info) {
      const appConfig = window.localStorage.getItem('appConfig')
      if (appConfig) {
        setTimeout(() => {
          this.$global.setItem('appConfig', JSON.parse(appConfig))
        }, 20)
      }
      if (info && typeof info === 'object') {
        window.sessionStorage.clear()
        window.localStorage.clear()
        this.set_refresh(null)
        const {jsessionId, userInfo, companyInfoList} = info
        const companyInfo = companyInfoList && companyInfoList.length ? companyInfoList[0] : undefined
        this.$global.setItem({
          JSESSIONID: jsessionId,
          // userInfo: userInfo,
          companyInfoList: companyInfoList,
          companyInfo: companyInfo
        })
        setTimeout(() => {
          this.$global.setItem('userInfo', userInfo, true)
          this.$global.setItem('companyInfo', companyInfo, true)
          this.$global.setItem('companyInfoList', companyInfoList, true)
        })
      }
    }
  }
}
